





































































































































.tools-container {
  position: absolute !important;
  background-color: transparent !important;
  width: auto !important;
  height: auto !important;
}

#workspace-favorites-fullscreen {
  position: absolute !important;
  background-color: transparent !important;
  width: auto !important;
  height: auto !important;
  top: auto !important;
  left: auto !important;
}

.utility-container {
  display: flex;
  justify-content: space-between;
  /* Distribuye los elementos horizontalmente */
  align-items: center;
  /* Centra los elementos verticalmente */
}

.centered {
  flex: 1;
  /* Expande este contenedor para centrar el enlace */
  display: flex;
  justify-content: center;
  /* Centra el enlace horizontalmente */
  align-items: center;
  /* Centra el enlace verticalmente */
}

.centered a {
  text-decoration: none;
}

.tools-container {}

.main-container-custom-valores {
  display: inline;
}

#registro {
  position: relative !important;
  left: 250% !important;
  top: 7px !important;
}

.addwaitings-workspace-container-style {
  left: 380% !important;
  top: -97px !important;
}

.favorites-workspace-container-style {
  left: 380% !important;
  top: -97px !important;
}

@media (max-width: 700px) {
  #registro {
    position: absolute !important;
    left: 0% !important;
    top: 0px !important;
  }

  .addwaitings-workspace-container-style {
    left: 0% !important;
    top: 0px !important;
  }

  .favorites-workspace-container-style {
    left: 0% !important;
    top: 0px !important;
  }
}


.fav-container {
  margin-right: 6px;
  cursor: pointer;
}

.table-info {
  margin: 20px;
}

#registro {
  position: absolute;
}

#stocks {
  .table-custom {
    height: auto;
    width: auto;
    margin-top: 25px;
    margin-bottom: 50px;
  }

  .header-table {
    margin-right: 50px !important;
  }
}

#valores {
  .table-custom {
    height: auto;
    width: 100%;
    margin-top: 25px;
    margin-bottom: 50px;
  }

  table {
    border-spacing: 10px !important;
  }

  .header-table {
    margin-right: 50px !important;
  }
}

.header-table {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  letter-spacing: 1px;
  text-transform: uppercase;
  text-align: center;
}

.top-table {
  width: 100%;
}

.table-custom {
  height: 280px;
  width: 417px;
  background-color: #F3F4F7;
  overflow-y: scroll;
  border: 1px solid lightgrey;
  border-radius: 5px;
}

.table-row {
  font-size: 12px;
  background-color: white;
  font-weight: normal;
}

#best-bullish-values {
  .header-table {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    width: 208.5px;
    height: 40px;
  }

  .table-custom {
    height: 220px;
    width: auto;
    background-color: #F3F4F7;
    overflow-y: scroll;
    border: 1px solid lightgrey;
    border-radius: 5px;
  }

  .table-row {
    font-size: 12px;
    height: 40px;
    background-color: white;
    font-weight: normal;
  }

  .top-table {
    width: 100%;
  }
}

#hot-values {
  .header-table {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    width: 208.5px;
    height: 40px;
  }

  .table-custom {
    height: 220px;
    width: auto;
    background-color: #F3F4F7;
    overflow-y: scroll;
    border: 1px solid lightgrey;
    border-radius: 5px;
  }

  .table-row {
    font-size: 12px;
    height: 40px;
    background-color: white;
    font-weight: normal;
  }

  .top-table {
    width: 100%;
  }
}

#best-sectors {
  .header-table {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    width: 208.5px;
    height: 40px;
  }

  .table-custom {
    height: 220px;
    width: auto;
    background-color: #F3F4F7;
    overflow-y: scroll;
    border: 1px solid lightgrey;
    border-radius: 5px;
  }

  .table-row {
    font-size: 12px;
    height: 40px;
    background-color: white;
    font-weight: normal;
  }

  .top-table {
    width: 100%;
  }
}

#portfolio-changes {
  .header-table {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    width: auto;
    height: 40px;
  }

  .table-custom {
    height: 220px;
    width: auto;
    background-color: #F3F4F7;
    overflow-y: scroll;
    border: 1px solid lightgrey;
    border-radius: 5px;
  }

  .table-row {
    font-size: 12px;
    height: 40px;
    background-color: white;
    font-weight: normal;
  }

  .top-table {
    width: 100%;
  }
}

#twin-souls {
  .header-table {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    width: 417px;
    height: 40px;
  }

  .table-custom {
    height: 180px;
    width: auto;
    background-color: #F3F4F7;
    overflow-y: scroll;
    border: 1px solid lightgrey;
    border-radius: 5px;
  }

  .table-row {
    font-size: 12px;
    height: 40px;
    background-color: white;
    font-weight: normal;
  }

  .top-table {
    width: 100%;
  }
}

#artificial-inteligence {
  .header-table {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    width: 139px;
    height: 40px;
  }

  .table-custom {
    height: 220px;
    width: auto;
    background-color: #F3F4F7;
    overflow-y: scroll;
    border: 1px solid lightgrey;
    border-radius: 5px;
  }

  .table-row {
    font-size: 12px;
    height: 40px;
    background-color: white;
    font-weight: normal;
  }

  .top-table {
    width: 100%;
  }
}

#waiting-values {
  .header-table {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    width: 104px;
    height: 40px;
  }

  .table-custom {
    height: 220px;
    width: auto;
    background-color: #F3F4F7;
    overflow-y: scroll;
    border: 1px solid lightgrey;
    border-radius: 5px;
  }

  .table-row {
    font-size: 12px;
    height: 40px;
    background-color: white;
    font-weight: normal;
  }

  .top-table {
    width: 100%;
  }
}

#open-positions {
  .header-table {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    width: 104px;
    height: 40px;
  }

  .table-custom {
    height: 220px;
    width: auto;
    background-color: #F3F4F7;
    overflow-y: scroll;
    border: 1px solid lightgrey;
    border-radius: 5px;
  }

  .table-row {
    font-size: 12px;
    height: 40px;
    background-color: white;
    font-weight: normal;
  }

  .top-table {
    width: 100%;
  }
}

#last-ipos {
  .header-table {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    width: 104px;
    height: 40px;
  }

  .table-custom {
    height: 280px;
    width: auto;
    background-color: #F3F4F7;
    overflow-y: scroll;
    border: 1px solid lightgrey;
    border-radius: 5px;
  }

  .table-row {
    font-size: 12px;
    height: 40px;
    background-color: white;
    font-weight: normal;
  }

  .top-table {
    width: 100%;
  }
}

#best-bullish {
  .header-table {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    width: 208.5px;
    height: 40px;
  }

  .table-custom {
    height: 220px;
    width: auto;
    background-color: #F3F4F7;
    overflow-y: scroll;
    border: 1px solid lightgrey;
    border-radius: 5px;
  }

  .table-row {
    font-size: 12px;
    height: 40px;
    background-color: white;
    font-weight: normal;
  }

  .top-table {
    width: 100%;
  }
}

#fundamental {
  .header-table {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    width: 357px;
    height: 40px;
  }

  .table-custom {
    width: 362px !important;
    height: 180px;
    width: auto;
    background-color: #F3F4F7;
    overflow-y: scroll;
    border: 1px solid lightgrey;
    border-radius: 5px;
  }

  .table-row {
    font-size: 12px;
    height: 40px;
    background-color: white;
    font-weight: normal;
  }

  .top-table {
    width: 100%;
  }
}

#fundamental-mobile {
  .header-table {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    width: 357px;
    height: 40px;
  }

  .table-custom {
    width: 332px !important;
    height: 180px;
    width: auto;
    background-color: #F3F4F7;
    overflow-y: scroll;
    border: 1px solid lightgrey;
    border-radius: 5px;
  }

  .table-row {
    font-size: 12px;
    height: 40px;
    background-color: white;
    font-weight: normal;
  }

  .top-table {
    width: 100%;
  }
}
