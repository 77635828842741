











































































































































































































































































































































































































































.ticker-name-container {
    background-color: #f3f4f7;
    width: 250px;
    height: 40px;
    margin: auto;
    text-align: center;
}

.ticker-name-container p {
    padding-top: 10px;
}

.container-alarm {
    margin-left: 5px;
    border: 1px solid lightgrey;
    border-left: 5px solid #42a0c9;
    display: block;
    margin-bottom: 10px;
    border-radius: 5px;
}

.container-note {
    margin-left: 5px;
    border: 1px solid lightgrey;
    border-left: 5px solid rgba(73, 76, 90, 0.25);
    display: block;
    margin-bottom: 10px;
    border-radius: 5px;
}

.container-portfolio {
    margin-left: 5px;
    border: 1px solid lightgrey;
    border-left: 5px solid #BA91BB;
    display: block;
    margin-bottom: 10px;
    border-radius: 5px;
}

.container-favorite {
    margin-left: 5px;
    border: 1px solid lightgrey;
    border-left: 5px solid #E39B0F;
    display: block;
    margin-bottom: 10px;
    border-radius: 5px;
}

.comment-box {
    display: flex;
    align-items: center;
    width: 95%;
    margin-left: 10px;
    height: 50%;
}

.comment-box label {
    margin-top: 5px;
    height: 40px !important;
}

.registry-date {
    width: 170px;
    height: 24px;

    /* Subtitle 2 */

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */

    letter-spacing: 0.1px;

    /* Tema Claro/02. Primary/900 */

    color: #000010;

    padding: 3px 0 0 5px;
    margin: 0px !important;
}

.registry-text {
    width: 341px;

    /* Caption */

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    /* or 133% */

    letter-spacing: 0.4px;

    /* Tema Claro/02. Primary/900 */

    color: #000010;

    padding: 5px 0 1px 15px;
}

.entries-container {
    overflow-y: scroll;
    height: 250px;
}

.ticker-and-entries {
    display: flex;
    flex-direction: column;
}

.registry-input-text {
    z-index: 100;
    bottom: 0px;
    border-top: 0.5px solid rgba(0, 0, 0, 0.12);
    width: 402px;
}

.fixed-top {
    z-index: 100;
    top: 70px;
}

#registro {
    border-radius: 20px;
    background-color: #F3F4F7 !important;
}

.cerrar-boton {
    cursor: pointer;
    background: transparent;
    border: none;
    font-size: 18px;
    color: #333;
    text-align: right;
    margin-right: 12px;
}
