
















































































































































































































































































































































































































































































































































































$color_dark: #000010;
$back_grey: #cdd1df;

#stock-img {
  margin: auto;
  vertical-align: middle;
}

.stocks-title {
  margin: 0;
  padding: 60px 5px 40px;
  color: $color_dark;
  font-size: 22px;
  font-weight: 600;
  line-height: 20px;
  position: relative;
  width: 100%;
}

.sector-content {
  margin: 40px 0;
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 auto;
  align-items: flex-start;
  justify-content: space-between;
}


.news-title {
  margin-top: 215px;
}

.noticias {
  display: flex;
}

.container-img {
  border: 1px solid transparent;
  border-radius: 10px;
}

.caja {
  font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif;
  color: #ffffff;
  font-weight: 400;
  text-align: center;
  background: transparent;
  margin: 0 0 15px;
  overflow: hidden;
  padding: 8px;
  border-radius: 0px 0px 0px 0px;
  -moz-border-radius: 0px 0px 0px 0px;
  -webkit-border-radius: 0px 0px 0px 0px;
  border-bottom: 1px solid black;
  display: flex;
}

.title-text {
  cursor: pointer;
  color: black;
  font-size: 17px;
  font-weight: 400;
  text-align: justify;
  margin-left: 5px;
  margin-top: 2px;
}

a {
  color: black;
  text-decoration: none;
}

.news-descrition {
  font-size: 14px;
  color: #444242;
}

.news-autor {
  font-size: 12px;
  color: #444242;
}

.titulito {
  font-size: 17px;
}

.similare {
  color: #00add9 !important;
}

#comment-user {
  font-size: 14px;
}

#comment-text,
.no-comment-text {
  letter-spacing: 0.4px;
  margin-top: 20px;
  flex-grow: 1;
  margin-left: 20px;
  color: rgb(0, 0, 0);
  font-size: 14px;
  line-height: 22px;
}

#comment-rating {
  display: flex;
  justify-content: end;
  align-items: center;
}

.comment-icon {
  margin: 0 2px;
  cursor: pointer;
}

.comment-text {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  margin-left: 2px;
  margin-left: 20px;
}

.text-bold {
  font-weight: bold;
}

.user-box {
  align-items: center;
  margin-bottom: 15px;
  line-height: 100%;
  width: 150px;
  min-width: 150px;
}

#comment {
  margin: 0 20px;
}

.comments {
  margin-top: 65px;
}

.comment-icon-user {
  font-size: 40px;
  margin-right: 15px;
}

.comment-box {
  display: flex;
  align-items: center;
  margin: 30px 0px;
  margin-bottom: 30px;
}

.comment-count-text {
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  line-height: 12px;
  margin: 10px 0 20px;
}

.mdc-text-field__input {
  height: 10px !important;
  margin-bottom: 30px !important;
}

#input_comment {
  flex-grow: 1;
}

.info-button {
  width: 200px;
  box-shadow: none !important;
  border: none !important;
  color: #00ADD9 !important;
  border-radius: 10px !important;
  cursor: pointer;
}

.info-button:hover {
  background-color: #ebebeb !important;
}

.description {
  text-align: justify;
  background-color: #FFF;
  color: #777;
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 14px;
  line-height: 22px;
  margin: 0;
}

.iframe-container {
  flex-grow: 1;
}

.member-since {
  margin-top: 10%;
  font-size: 10px;
  text-align: center;
}

.user-picture {
  height: 100px;
  text-align: center;
}

.tthh-user {
  display: table;
  margin: 0 auto;
  margin-top: 10px;
}

.tthh-user-date-comment {
  display: table;
  margin: 0 auto;
  float: none !important;
  margin-top: 10px;
}

.forum-heading {
  text-align: center;
  margin-bottom: 10px;
}

.info-parameter.mobile {
  margin-left: 15px;
}

#chart-container {
  display: flex;
}

@media (max-width: 600px) {
  #chart-container {
    display: block !important;
  }
}

.no_visible {
  display: none;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.pagination button {
  background: none;
  border: none;
  color: #007bff;
  padding: 0;
  margin: 0 2px;
  cursor: pointer;
  font-size: 14px;
}

.pagination button:hover {
  text-decoration: underline;
}

.pagination button.active {
  font-weight: bold;
}

.workspace-css {
  position: relative !important;
  top: 50% !important;
  left: 50% !important;
}


